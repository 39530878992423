import React from "react"
import { Divider as DividerOg } from "@chakra-ui/react"
import type { PropsWithStyle } from "@mallardbay/lib-react-components"
import { componentStyles } from "@mallardbay/lib-react-components"

interface Props extends PropsWithStyle {
    readonly noMargin?: boolean
}

export default function Divider({ style, noMargin }: Props) {
    const styles = useStyles({ noMargin })

    return <DividerOg sx={{ ...styles.root, ...style }} />
}

function useStyles({ noMargin }: { noMargin?: boolean }) {
    return componentStyles({
        root: {
            marginY: noMargin ? 0 : 4,
        },
    })
}
