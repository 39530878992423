import { navigate } from "gatsby"
import { last } from "radash"

import { HUBSPOT_URL, REFERRAL_ID_SEARCH_PARAM } from "~config/constants"

// NAVIGATION
export function navigateToOutfitter(outfitterId: string) {
    return navigate(`/outfitter/${outfitterId}`)
}

export function navigateToBooking(bookingId?: string) {
    return navigate(`/booking/${bookingId}`)
}

export function navigateToAdditionalPayment(bookingId: string) {
    return navigate(`/booking/${bookingId}/additional-payment`)
}

export function navigateToReferralsPage(tabName: string) {
    return navigate(`/referrals/${tabName}`)
}

export function navigateToAdditionalBookingPayment(bookingId: string) {
    return navigate(`/booking/${bookingId}/additional-booking-payment`)
}

export function navigateToEditCalendar(
    resourceType: string,
    resourceId: string
) {
    const url = getNavigateToEditCalendarUrl(resourceType, resourceId)
    return navigate(url)
}

export function navigateToOutfitterWithDelay(outfitterId: string) {
    const url = getOutfitterUrl(outfitterId)
    navigateWithDelay(url, 200)
}

export function navigateToSendQuotePage() {
    return navigate(getSendQuoteRoute())
}

export function navigateToBookingInviteForm({
    bookingInviteId,
    bookingReferralId,
}: {
    bookingInviteId?: string
    bookingReferralId?: string
} = {}) {
    const url = getBookingInviteFormUrl({ bookingInviteId, bookingReferralId })

    return navigate(url)
}

export function navigateToManageListingPage(listingId: string) {
    const url = getManageListingUrl(listingId)

    return navigate(url)
}

export function navigateToGettingStarted() {
    const url = getGettingStartedUrl()

    return navigate(url)
}

export function navigateToReporting() {
    const url = getReportingUrl()

    return navigate(url)
}

function navigateWithDelay(url: string, delay = 200) {
    setTimeout(() => {
        void navigate(url)
    }, delay)
}

// URLS
function getOutfitterUrl(outfitterId: string | null | undefined): string {
    return `/outfitter/${outfitterId}`
}

export function getGettingStartedUrlWithDomain() {
    return `${process.env.GATSBY_MB_ADMIN_URL}${getGettingStartedUrl()}`
}

function getGettingStartedUrl() {
    return `/getting-started`
}

function getReportingUrl() {
    return `/reporting`
}

export function getWebOutfitterUrl(outfitterSlug: string): string {
    return `${process.env.GATSBY_MB_ROOT_URL}/${outfitterSlug}`
}

export function getWidgetInstructionsUrl(widgetId: string) {
    return `/widget/${widgetId}/instructions`
}

export function getCalendarUrl() {
    return `/calendar`
}

export function getCreateWidgetUrl(outfitterId: string) {
    return `/widget/new/${outfitterId}`
}

export function getWidgetUrl(widgetId: string) {
    return `/widget/${widgetId}`
}

export function getEditCalendarUrl() {
    return "/calendar/edit"
}

export function getListingUrl({ listingId }: { listingId: string }) {
    return `${process.env.GATSBY_MB_ROOT_URL}/listing/${listingId}`
}

function getBookingInviteFormUrl({
    bookingInviteId,
    bookingReferralId,
}: {
    bookingInviteId?: string
    bookingReferralId?: string
}) {
    const baseRoute = `${getSendQuoteRoute()}/form`

    const formUrl = bookingInviteId
        ? `${baseRoute}/${bookingInviteId}`
        : baseRoute
    const searchParams = bookingReferralId
        ? `?${REFERRAL_ID_SEARCH_PARAM}=${bookingReferralId}`
        : ""

    return `${formUrl}${searchParams}`
}

function getSendQuoteRoute() {
    return "/send-quote"
}

function getNavigateToEditCalendarUrl(
    resourceType: string,
    resourceId: string
) {
    const lowerResourceType = resourceType.toLowerCase()
    return `/calendar/edit/${lowerResourceType}/${resourceId}`
}

export function getAccountPageUrl() {
    return `${process.env.GATSBY_MB_ROOT_URL}/account`
}

export function getPaymentRequestUrl(paymentRequestId: string) {
    return `${process.env.GATSBY_MB_ROOT_URL}/payment-request/${paymentRequestId}`
}

export function getManageListingUrl(id: string) {
    return `/manage-listings/${id}`
}

export function getFullRoute() {
    const defaultRoute = window.location.pathname
    const { href, host } = window.location
    if (!href || !host) {
        return defaultRoute
    }
    // full route will be pathname plus any query string or hash suffixes (i.e. /outfitters?search=name or /outfitter/{id}/#add-ons)
    const fullRoute = last(window.location.href.split(window.location.host))

    return fullRoute ?? defaultRoute
}

export function getHubspotCompanyLink(companyId: string) {
    return `${HUBSPOT_URL}/contacts/${process.env.GATSBY_HUBSPOT_ID}/record/0-2/${companyId}`
}
