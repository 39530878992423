import type { IconButtonProps as IconButtonPropsOg } from "@chakra-ui/react"
import { IconButton as IconButtonOg } from "@chakra-ui/react"
import type { StyleProps } from "@mallardbay/lib-react-components"
import type { MouseEventHandler } from "react"
import React from "react"

interface IconButtonProps {
    readonly colorScheme?: IconButtonPropsOg["colorScheme"]
    readonly ariaLabel: string
    readonly icon: React.ReactElement
    readonly onClick?: MouseEventHandler<HTMLButtonElement>
    readonly style?: StyleProps
    // We need both versions. rc-pagination needs disabled.
    readonly isDisabled?: boolean
    readonly disabled?: boolean
    readonly testId?: string
    readonly variant?: IconButtonPropsOg["variant"]
    readonly size?: IconButtonPropsOg["size"]
    readonly isLoading?: boolean
}

export function IconButton({
    colorScheme,
    ariaLabel,
    icon,
    onClick,
    style,
    isDisabled,
    disabled,
    testId,
    variant,
    size,
    isLoading,
}: IconButtonProps) {
    return (
        <IconButtonOg
            sx={style}
            rounded="full"
            size={size}
            colorScheme={colorScheme}
            aria-label={ariaLabel}
            icon={icon}
            onClick={onClick}
            isDisabled={isDisabled || disabled}
            data-testid={testId}
            variant={variant}
            isLoading={isLoading}
        />
    )
}
