import type { PropsWithChildren } from "react"
import React from "react"
import {
    Provider,
    useCoreThemeForProvider,
} from "@mallardbay/lib-react-components"
// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { ChakraProvider } from "@chakra-ui/react"

// Unfortunately, it does not seem like we can have a single
// provider for the chakra components inside mallardbay/lib-react-components and
// chakra-ui/react, so for now we will have to have two providers.
// Eventually we will pnly import components from mallardbay/lib-react-components
export default function ThemeProvider(props: Readonly<PropsWithChildren>) {
    const themeForProvider = useCoreThemeForProvider()

    return (
        <Provider theme={themeForProvider}>
            <ChakraProvider theme={themeForProvider} {...props} />
        </Provider>
    )
}
