import React from "react"
// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import type { AvatarProps, CenterProps, IconProps } from "@chakra-ui/react"
// TODO move to @mallardbay/lib-react-components
// eslint-disable-next-line no-restricted-imports
import { Icon, Center, Avatar } from "@chakra-ui/react"
import type { IconType } from "react-icons"
import { useColors } from "@mallardbay/lib-react-components"

interface Props {
    readonly icon?: IconType
    readonly label: string
    readonly isHighlighted: boolean
}

export default function SidebarIcon({ icon, label, isHighlighted }: Props) {
    const styleProps = useStyleProps({ isHighlighted })

    if (icon) {
        return (
            <Center {...styleProps.iconCenter}>
                <Icon {...styleProps.icon} as={icon} />
            </Center>
        )
    }

    return (
        <Center {...styleProps.avatarCenter}>
            <Avatar {...styleProps.avatar} name={label} />
        </Center>
    )
}

function useStyleProps({ isHighlighted }: { isHighlighted: boolean }) {
    const colors = useColors()

    const sharedCenterProps: CenterProps = {
        mx: 3,
        borderRadius: "lg",
    }
    const avatarCenter: CenterProps = {
        ...sharedCenterProps,
        padding: "2px",
        bgColor: isHighlighted ? "brand.200" : "gray.200",
    }
    const iconCenter: CenterProps = {
        ...sharedCenterProps,
        padding: "8px",
        bgColor: isHighlighted ? colors.focusBorderColor : undefined,
    }
    const avatar: AvatarProps = {
        size: "sm",
        boxSize: "30px",
        borderRadius: "md",
    }
    const icon: IconProps = {
        height: "18px",
        width: "18px",
        color: isHighlighted ? "gray.50" : colors.label,
        opacity: isHighlighted ? 1 : 0.8,
    }

    return { avatarCenter, iconCenter, icon, avatar }
}
